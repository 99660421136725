import React, { useState, useEffect } from "react"

// Libraries
import styled, { keyframes } from "styled-components"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Components
import Container from "components/container/"
import Form from "components/forms/"
import Dropdown from "components/form-dropdowns/"
import Lottie from "components/lottie/"
import LinkWithButton from "components/link-with-icon/"
import Button from "components/button-with-icon/"

// Animations
import SuccessAnimationOne from "assets/animations/contact/success-animation-1.json"
import SuccessAnimationTwo from "assets/animations/contact/success-animation-2.json"
import ErrorAnimationOne from "assets/animations/contact/error-animation-1.json"
import ErrorAnimationTwo from "assets/animations/contact/error-animation-2.json"

// Icons
import IconCheck from "assets/icons/icon-check.inline.svg"

const StyledContainer = styled(Container)`
  max-width: 736px;

  ${breakpoint.small`
    padding: 0;
  `}
`

const StyledForm = styled(Form)`
  .form__group {
    position: relative;
  }

  input,
  textarea {
    &:focus {
      &::placeholder {
        color: ${colors.white};
      }
    }

    &.error {
      border-color: ${colors.watermelon};

      & + small {
        display: block;
      }
    }
  }

  textarea {
    height: 41px;
    transition: height 0.6s ease-in-out;

    &:focus,
    &:valid {
      height: 240px;

      ${breakpoint.small`
        height: 95px;
      `}
    }
  }

  .message {
    margin-top: 8px;
    font-size: 16px;

    &--error {
      color: ${colors.watermelon};
    }
  }
`

const StyledFormSection = styled.section`
  position: relative;
  padding: 80px 0;

  ${breakpoint.medium`
    padding: 160px 0 125px 0;
  `}

  .title {
    margin-bottom: 40px;

    ${breakpoint.medium`
      margin-bottom: 56px;
    `}

    b {
      font-weight: 500;
    }
  }
`

const dotCollisionBefore = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
`

const dotCollisionAfter = keyframes`
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
`

const StyledSubmitButton = styled.button`
  min-width: 180px;
  min-height: 58px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  background-color: ${props =>
    props.loading ? colors.midnight : colors.indiblue};
  color: ${colors.white};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${colors.midnight};
  }

  .dots {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
  }

  .dots::before,
  .dots::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dots::before {
    left: -10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
    animation: ${dotCollisionBefore} 1s infinite ease-in;
  }

  .dots::after {
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.white};
    color: ${colors.white};
    animation: ${dotCollisionAfter} 1s infinite ease-in;
    animation-delay: 0.5s;
  }
`

const FormConfirmation = styled.div`
  max-width: 1120px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background-color: ${colors.white};
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  transition: all 0.6s;

  &.confirmation--success {
    h1,
    .subtitle {
      color: ${colors.indiblue};
    }
  }

  &.confirmation--error {
    h1,
    .subtitle {
      color: ${colors.watermelon};
    }
  }

  .content {
    order: 1;
    max-width: 440px;
    padding: 0 24px;

    ${breakpoint.small`
      padding: 0 48px;
    `}

    ${breakpoint.medium`
      order: 0;
      padding: 0;
    `}
  }

  .image {
    order: 0;

    ${breakpoint.small`
      order: 1;
      max-width: 480px;
      width: calc(100% - 472px);
    `}

    svg {
      width: 100%;
    }
  }

  h1,
  .subtitle {
    font-family: ${fonts.primary};
    font-weight: 500;
  }

  h1 {
    max-width: 400px;
    margin-bottom: 8px;
  }

  .subtitle {
    margin-bottom: 40px;
  }
`

const SuccessAnimations = [SuccessAnimationOne, SuccessAnimationTwo]
const ErrorAnimations = [ErrorAnimationOne, ErrorAnimationTwo]

const FormSection = () => {
  // State
  const [formFields, setFormFields] = useState({
    name: "",
    projectDetails: "",
    email: "",
    timeline: "We have 1-2 months to complete the project",
    budget: "$5,000 - $15,000 USD",
  })
  const [formValidation, setFormValidation] = useState({
    name: true,
    projectDetails: true,
    email: true,
    timeline: true,
    budget: true,
  })
  const [subscribesToNewsletter, setSubscribesToNewsletter] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successAnimation, setSuccessAnimation] = useState(null)
  const [errorAnimation, setErrorAnimation] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  // Hooks
  const { t } = useTranslation()

  // Sets 'Success' and 'Error' animations
  useEffect(() => {
    setSuccessAnimation(
      SuccessAnimations[
        Math.floor(Math.random() * SuccessAnimations.length) + 0
      ]
    )

    setErrorAnimation(
      ErrorAnimations[Math.floor(Math.random() * ErrorAnimations.length) + 0]
    )
  }, [])

  const validateForm = () => {
    const _formValidation = formValidation

    for (const [key, value] of Object.entries(formFields)) {
      _formValidation[key] = value !== ""
    }

    setFormValidation({
      ...formValidation,
      ..._formValidation,
    })
  }

  const handleInputChange = event => {
    const {
      target: { name, value },
    } = event

    setFormFields({
      ...formFields,
      [name]: value,
    })
  }

  const handleDropdownChange = event => {
    const {
      target: { name, textContent: value },
    } = event

    setFormFields({
      ...formFields,
      [name]: value,
    })
  }

  const handleSuccess = () => {
    if (!showSuccess) {
      setShowSuccess(true)
    }

    // Fires GA event
    const dataLayer = window.dataLayer || []
    dataLayer.push({
      event: "contact_form_submission",
    })
  }

  const handleError = () => {
    if (!showError) {
      setShowError(true)
    }
  }

  const closeResponse = () => {
    if (showSuccess) {
      setShowSuccess(false)
    }

    if (showError) {
      setShowError(false)
    }
  }

  const formIsValid = () => {
    return Object.values(formValidation).indexOf(false) === -1
  }

  const handleSubmission = event => {
    event.preventDefault()

    validateForm()

    const isValid = formIsValid()

    if (isValid) {
      setLoading(true)

      const data = {
        fields: [
          {
            name: "firstname",
            value: formFields["name"],
          },
          {
            name: "message",
            value: formFields["projectDetails"],
          },
          {
            name: "email",
            value: formFields["email"],
          },
          {
            name: "timeline",
            value: formFields["timeline"],
          },
          {
            name: "budget",
            value: formFields["budget"],
          },
          {
            name: "lifecyclestage",
            value: "lead",
          },
        ],
        context: {
          pageUri: "https://indicius.com/contact",
          pageName: "Contact",
        },
      }

      // Submits form to HubSpot
      fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/2094487/7ce95538-15b0-4626-89e5-b1642f0a87a8",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Content-Length": data.length,
          },
          body: JSON.stringify(data),
        }
      )
        .then(response => {
          if (response.status === 200) {
            handleSuccess()
          } else {
            handleError()
          }
        })
        .catch(() => {
          handleError()
        })

      if (subscribesToNewsletter) {
        fetch(
          "https://api.hsforms.com/submissions/v3/integration/submit/2094487/84c23991-fa1c-4c37-87ea-e1ed14d3f498",
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Content-Length": data.length,
            },
            body: JSON.stringify({
              fields: [
                {
                  name: "email",
                  value: formFields["email"],
                },
                {
                  name: "lifecyclestage",
                  value: "subscriber",
                },
              ],
              context: {
                pageUri: window.location.href,
              },
            }),
          }
        )
      }
    }
  }

  return (
    <StyledFormSection>
      <StyledContainer>
        <p className="title">
          <Trans>
            What do you need help with? Have a project or an idea you'd like to
            collaborate on?
          </Trans>{" "}
          <b>
            <Trans>Let’s talk about it.</Trans>
          </b>
        </p>
        <StyledForm onSubmit={handleSubmission} noValidate>
          <div className="form__group">
            <label htmlFor="name" className="form__label">
              <Trans>What's your name?</Trans>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Daenerys Targaryen"
              onChange={handleInputChange}
            />
            {!formValidation["name"] && (
              <small className="message message--error">
                <Trans>Don't forget to tell us your name!</Trans>
              </small>
            )}
          </div>
          <div className="form__group">
            <label htmlFor="project_details" className="form__label">
              <Trans>Tell us about the details of your project</Trans>
            </label>
            <textarea
              required
              name="projectDetails"
              placeholder={t("I need to conquer Westeros.")}
              onChange={handleInputChange}
            />
            {!formValidation["projectDetails"] && (
              <small className="message message--error">
                <Trans>Don't forget to tell us about your project!</Trans>
              </small>
            )}
          </div>
          <div className="form__group">
            <label htmlFor="email" className="form__label">
              <Trans>What's your email?</Trans>
            </label>
            <input
              type="email"
              name="email"
              placeholder="dany_dracarys@targaryen.com"
              onChange={handleInputChange}
            />
            {!formValidation["email"] && (
              <small className="message message--error">
                <Trans>Oopsie! This email is not valid.</Trans>
              </small>
            )}
          </div>
          <div className="form__group">
            <label htmlFor="timeline" className="form__label">
              <Trans>Do you have a timeline for the project?</Trans>
            </label>

            <Dropdown
              name="timeline"
              defaultOption={t("We have 1-2 months to complete the project")}
              options={[
                t("We have a tight deadline"),
                t("We have 1-2 months to complete the project"),
                t("We have 3+ months to complete the project"),
                t("We're looking for ongoing work"),
                t("Other"),
              ]}
              callbackFunction={handleDropdownChange}
            />
          </div>

          <div className="form__group">
            <label htmlFor="budget" className="form__label">
              <Trans>What’s the budget for the project?</Trans>
            </label>

            <Dropdown
              name="budget"
              options={[
                t("$5,000 - $15,000 USD"),
                t("$15,000 - $30,000 USD"),
                t("$30,000 - $50,000 USD"),
                t("$50,000 USD and beyond"),
                t("Other"),
              ]}
              callbackFunction={handleDropdownChange}
            />
          </div>

          <div className="form__group d-flex">
            <button
              type="button"
              className={`checkbox mt-1 me-2 ${subscribesToNewsletter &&
                "active"}`}
              onClick={() => setSubscribesToNewsletter(!subscribesToNewsletter)}
            >
              {subscribesToNewsletter && (
                <IconCheck className="svg--stroke-white" />
              )}
            </button>
            <label htmlFor="" style={{ fontSize: "18px" }}>
              <Trans>I agree to receive updates from Indicius</Trans>
            </label>
          </div>

          <div className="form__group align--right">
            <StyledSubmitButton loading={loading}>
              {loading ? <div className="dots" /> : <Trans>Send Request</Trans>}
            </StyledSubmitButton>
          </div>
        </StyledForm>
      </StyledContainer>

      <FormConfirmation className="confirmation--success" visible={showSuccess}>
        <div className="content">
          <h1>
            <Trans>Thanks for</Trans>
            <br />
            <Trans>getting in touch!</Trans>
          </h1>
          <p className="subtitle">
            <Trans>You'll hear from us soon.</Trans>
          </p>
          <br />
          <LinkWithButton
            to="/culture/"
            text={t("Get to know our team")}
            iconColor={colors.indiblue}
          />
        </div>
        <div className="image">
          <Lottie loop autoplay={showSuccess} animation={successAnimation} />
        </div>
      </FormConfirmation>

      <FormConfirmation className="confirmation--error" visible={showError}>
        <div className="content">
          <h1>
            <Trans>Crickey!</Trans>
            <br />
            <Trans>Something's not working.</Trans>
          </h1>
          <p className="subtitle">
            <Trans>It seems like your email wasn't sent.</Trans>
          </p>
          <br />
          <Button text={t("Try sending it again")} onClick={closeResponse} />
        </div>
        <div className="image">
          <Lottie loop autoplay={showError} animation={errorAnimation} />
        </div>
      </FormConfirmation>
    </StyledFormSection>
  )
}

export default FormSection
