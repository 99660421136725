import React from "react"

// Utils
import { colors } from "utils/variables/"

//Libraries
import { graphql } from "gatsby"

// Layout
import Layout from "layouts/layout-primary"

// Context
import ContextConsumer from "components/context/"

// Components
import Seo from "components/seo/"

// Page Sections
import Hero from "sections/contact/hero"
import Form from "sections/contact/form"
import Careers from "sections/contact/careers"
import { useTranslation } from "react-i18next"

const Contact = () => {
  const { t } = useTranslation()

  return (
    <Layout menuColor={colors.midnight}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (!data.showFooter) {
            set({ showFooter: true })
          }
          if (!data.simplifiedFooter) {
            set({ simplifiedFooter: true })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Contact - Indicius Design Agency")}
        description={t(
          "Get in touch if you are looking for a website, app, or if you want to experience how great our Design Sprints and branding workshops are. Let's make something great, together."
        )}
      />
      <Hero />
      <Form />
      <Careers />
    </Layout>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default Contact
