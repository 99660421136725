import React from "react"

//Libraries
import { useTranslation } from "react-i18next"

// Components
import PageHero from "components/page-hero/"

const Hero = () => {
  const { t } = useTranslation()
  return (
    <PageHero
      title={t("Let's make something great together.")}
      highlight={t("Tell us about you.")}
    />
  )
}

export default Hero
