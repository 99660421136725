import React from "react"

// Libraries
import Lottie from "react-lottie"

const LottieComponent = props => {
  const { loop, autoplay, animation, path } = props

  const options = {
    loop: loop || false,
    autoplay: autoplay || false,
    animationData: animation,
    path: path,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return <Lottie options={options} isClickToPauseDisabled={true} />
}

export default LottieComponent
