import React, { Fragment } from "react"
import styled, { css } from "styled-components"
import Lottie from "react-lottie"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Container from "components/container/"

// Icons
import Clipboard from "assets/icons/icon-clipboard.inline.svg"
import Check from "assets/icons/icon-check-with-circle.inline.svg"

// Animations
import CareersAnimation from "assets/animations/contact/careers-animation.json"
import DesignAnimation from "assets/animations/contact/design-animation.json"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  ${breakpoint.medium`
    flex-wrap: nowrap;
  `}
`

const StyledCareers = styled.section`
  padding-bottom: 80px;

  ${breakpoint.medium`
    padding-bottom: 160px;
  `}
`

const StyledBlock = styled.div`
  position: relative;
  padding: 48px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  font-size: 18px;
  text-align: left;
  box-sizing: border-box;

  ${breakpoint.medium`
    width: 50%;
    height: 340px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {

      .block__animation {
        width: 100%;

        > div {
          opacity: 1;
          transition: opacity .6s ease .6s;
        }
      }
    }
  `}

  .block__animation {
    display: none;

    ${breakpoint.medium`
      width: 0;
      height: 100%;
      position: absolute;
      display: block;
      background-color: ${props => props.backgroundColor};
      z-index: 100;
      transition: width .6s ease;

      ${props =>
        props.animateToRight
          ? css`
              left: 100%;
            `
          : css`
              right: 100%;
            `}

      > div {
        opacity: 0;
        transition: opacity .1s ease;
      }
    `}
  }

  .block__content {
    position: relative;

    ${breakpoint.medium`
      max-width: 352px;
    `}

    .title {
      margin-bottom: 8px;
      color: ${props =>
        props.backgroundColor === colors.indiblue
          ? colors.supernova
          : colors.indiblue};
      font-size: 24px;
      font-weight: 500;
    }

    .mail {
      cursor: pointer;
      color: ${props =>
        props.backgroundColor === colors.supernova
          ? colors.midnight
          : colors.white};
      font-weight: 500;

      &:hover {
        & + .content__clipboard {
          opacity: 1;
        }
      }
    }

    .content__clipboard {
      display: flex;
      align-items: center;
      font-size: 16px;
      position: absolute;
      top: calc(100% + 4px);
      color: ${props =>
        props.backgroundColor === colors.supernova
          ? colors.stone
          : colors.cloudy};
      opacity: 0;

      ${breakpoint.medium`
        top: calc(100% + 8px);
      `}

      svg {
        margin-right: 8px;

        * {
          stroke: ${props =>
            props.backgroundColor === colors.supernova
              ? colors.stone
              : colors.cloudy};
        }
      }
    }
  }
`

class Block extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMouseOver: false,
      hasBeenCopied: false,
      email: props.mail,
    }
  }

  handleMouseOver = event => {
    if (!this.state.isMouseOver) {
      this.setState({
        isMouseOver: true,
      })
    }
  }

  handleMouseLeave = () => {
    if (this.state.isMouseOver) {
      this.setState({
        isMouseOver: false,
      })
    }
  }

  resetClipboard = () => {
    if (this.state.hasBeenCopied) {
      this.setState({
        hasBeenCopied: false,
      })
    }
  }

  handleCopyToClipboard = () => {
    // Incredibly, we need to create
    // a fucking textarea for this. Yikes.
    // Here we go:
    const textarea = document.createElement("textarea")
    textarea.value = this.state.email /* Add the text we want to be copied */
    textarea.style.opacity = "0" /* Hide it */

    document.body.appendChild(textarea) /* Add it to the <body> */

    textarea.select() /* This mimics the "select" action */
    document.execCommand("copy") /* Finally */

    // Destroy this monster
    document.body.removeChild(textarea)

    // Sets the state
    if (!this.state.hasBeenCopied) {
      this.setState({
        hasBeenCopied: true,
      })
    }
  }

  render = props => {
    const options = {
      loop: true,
      autoplay: false,
      animationData: this.props.animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }

    return (
      <StyledBlock
        backgroundColor={this.props.backgroundColor}
        color={this.props.color}
        animateToRight={this.props.animateToRight}
        onMouseOver={this.handleMouseOver}
        onFocus={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="block__content">
          <p className="title">{this.props.title}</p>
          <p className="paragraph--small">{this.props.description}</p>
          <br />
          <button
            type="button"
            className="mail"
            onClick={this.handleCopyToClipboard}
            onMouseLeave={this.resetClipboard}
          >
            {this.props.mail}
          </button>

          <div className="content__clipboard">
            {!this.state.hasBeenCopied ? (
              <Fragment>
                <Clipboard />
                Copy mail to clipboard
              </Fragment>
            ) : (
              <Fragment>
                <Check />
                Mail copied to clipboard
              </Fragment>
            )}
          </div>
        </div>

        <div className="block__animation">
          <Lottie
            options={options}
            isPaused={!this.state.isMouseOver}
            isClickToPauseDisabled={true}
          />
        </div>
      </StyledBlock>
    )
  }
}

const Careers = () => {
  const { t } = useTranslation()
  return (
    <StyledCareers>
      <StyledContainer>
        <Block
          backgroundColor={colors.supernova}
          color={colors.midnight}
          mail="jobs@indicius.com"
          title={t("Careers")}
          description={t(
            "Are you interested in joining the Indicius family? We're always interested in meeting new people."
          )}
          animation={CareersAnimation}
          animateToRight
        />

        <Block
          backgroundColor={colors.indiblue}
          color={colors.white}
          mail="hi@indicius.com"
          title={t("Design & Stuff")}
          description={t(
            "Say hello, talk about design or impact, or send kudos. Don't be shy, write us and we'll get back to you."
          )}
          animation={DesignAnimation}
        />
      </StyledContainer>
    </StyledCareers>
  )
}

export default Careers
